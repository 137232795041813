<script setup>
import { RouterView } from 'vue-router'
import '@fontsource/plus-jakarta-sans' // Defaults to weight 400
import '@fontsource/plus-jakarta-sans/500.css' // Specify weight
import { createSpinPot } from '@/hook/spinHook'
import { message } from 'ant-design-vue'
import { needloginFlag } from '@/hook/axiosUtils'
import googlelogin from '@/components/googlelogin/index.vue'
const spin = createSpinPot('app')

message.config({
  prefixCls: 'grados-message',
  // duration: 0,
})
</script>

<template>
  <a-spin :spinning="spin.spinning">
    <RouterView />
  </a-spin>
  <googlelogin v-model:open="needloginFlag" force />
</template>

<style scoped></style>
